<template>
  <div class="box">
      <ul class="main-box">
          <li class="bb main-title">
              <div class="title-line"></div>
              <div>订单信息</div>
          </li>
          <li class="bb list">
              <div class="item name br">订单编号</div>
              <div class="item text">{{orderNo}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">下单时间</div>
              <div class="item text">{{orderList.payTime}}</div>
          </li>
          
          <li class="bb list">
              <div class="item name br">支付时间</div>
              <div class="item text">{{orderList.payTime}}</div>
          </li>
      </ul>
      <ul class="main-box" style="margin-top: 20px;">
          <li class="bb main-title">
              <div class="title-line"></div>
              <div>发票信息</div>
          </li>
          <li class="bb list">
              <div class="item name br">发票类型</div>
              <div class="item text">{{invoiceDetails.invoiceType == 0? '普通发票': '增值税专用发票'}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">抬头类型</div>
              <div class="item text">{{invoiceDetails.titleType == 0? '单位': '个人'}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">抬头名称</div>
              <div class="item text">{{invoiceDetails.invoiceTitle}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">开票金额</div>
              <div class="item text">{{totalPurchaseFee/10000}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">申请时间</div>
              <div class="item text">{{invoiceDetails.createTime}}</div>
          </li>
          <li class="bb list">
              <div class="item name br">开票时间</div>
              <div class="item text">{{invoiceDetails.updateTime}}</div>
          </li>
      </ul>

  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: '',
  data(){
      return{
          orderNo: '',
          orderList: {},
          invoiceDetails: {},
          totalPurchaseFee: ''
      }
  },
  mounted(){
    this.orderNo = this.$route.query.orderNo;
    this.getData()
  },
  methods:{
    getData() {
        this.qa.queryUocOrderInfoForBuyer({orderNo: this.orderNo}).then(res => {
            this.orderList = res.data
        })

        this.qa.queryByInvoiceOrderRel({orderNo: this.orderNo}).then(res => {
            this.invoiceDetails = res.data.invoiceRecordRspBO;
            this.totalPurchaseFee = res.data.uocOrderRspBO.totalPurchaseFee
        })
        
    }

  }
}
</script>
<style scoped>
    .box {
        width: 1020px;
        height: 702px;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 30px;
    }
    .main-box {
        width: 100%;

        border-radius: 8px;
        border: 1px solid #EFFAE8;
        font-size: 14px;
    }
    .bb{
        border-bottom: 1px solid #EFFAE8;
    }
    .br{
        border-right: 1px solid #EFFAE8;
    }
    .main-title {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        background: #EFFAE8;
    }
    .title-line {
        width: 4px;
        height: 14px;
        background: #84C754;
        border-radius: 2px;
        margin: 0 7px 0 20px;
    }
    .list {
        width: 100%;
        height: 60px;
        display: flex;
    }
    .list div {
        height: 60px;
        line-height: 60px;
    }
    .name {
        width: 124px;
        box-sizing: border-box;
        padding-left: 31px;
    }
    .text {
        flex: 1;
        box-sizing: border-box;
        padding-left: 31px;
    }
</style>